import { PluginPipeline } from '@ads/plugin-pipeline';
import { loggerServicePlugin } from '@/features/core/logger';
import { ordersServicePlugin, OrderLocalStatus } from '@/features/orders';
import { errorPlugin } from '@/features/core/errors';
import router from '@/features/core/router';
import {
  AppCrashPlugin,
  EmitEventBusStatusChangePlugin,
  UpdateLocalStatusPlugin,
  TrackOrderEventPlugin,
  LogOrderPlugin,
} from '@/features/orders/plugins';
import { OrderEventNames } from '@/features/orders';
import { useOnlineStatus } from '@/features/orders/composables';
import type { OrderPickingCompletionPluginDto } from '../types';
import { SavePickingCompletedOrderPlugin } from '../plugins';

export function orderPickingCompletionPluginRegister(): PluginPipeline<OrderPickingCompletionPluginDto> {
  const orderPluginDtoPluginPipeline =
    new PluginPipeline<OrderPickingCompletionPluginDto>();

  orderPluginDtoPluginPipeline.registerPlugins([
    new EmitEventBusStatusChangePlugin('picking-completed'),
    new LogOrderPlugin(
      loggerServicePlugin.get(),
      'The user confirms the complete picking for Order {{id}}',
    ),
    new TrackOrderEventPlugin(
      ordersServicePlugin.get(),
      loggerServicePlugin.get(),
      OrderEventNames.staging_completed,
      {
        skipSaving: true,
      },
    ),
    new UpdateLocalStatusPlugin(
      loggerServicePlugin.get(),
      OrderLocalStatus.PickingCompleted,
    ),
    new AppCrashPlugin(),
    new SavePickingCompletedOrderPlugin(
      ordersServicePlugin.get(),
      loggerServicePlugin.get(),
      errorPlugin.get(),
      router.get(),
      useOnlineStatus().isOnline,
    ),
  ]);

  return orderPluginDtoPluginPipeline;
}
