import type { Ref } from 'vue';
import type {
  CompletePipelineWith,
  PipelinePlugin,
} from '@ads/plugin-pipeline';
import type { OrdersService } from '@/features/orders';
import { Order, parseOrderBeforeSave } from '@/features/orders';
import type { LoggerService } from '@/features/core/logger';
import type { OrderPickingCompletionPluginDto } from '../types';
import type { ErrorHandler } from '@/features/core/errors';
import {
  AppCrashPlugin,
  EmitEventBusStatusChangePlugin,
  UpdateLocalStatusPlugin,
  TrackOrderEventPlugin,
} from '@/features/orders/plugins';
import type { Router } from '@/features/core/router/types';

/**
 * Save picking completed order
 **/
export class SavePickingCompletedOrderPlugin
  implements PipelinePlugin<OrderPickingCompletionPluginDto>
{
  public readonly requiredPlugins = [
    EmitEventBusStatusChangePlugin,
    TrackOrderEventPlugin,
    UpdateLocalStatusPlugin,
    AppCrashPlugin,
  ];

  constructor(
    private ordersService: OrdersService,
    private loggerService: LoggerService,
    private errorHandler: ErrorHandler,
    private router: Router,
    private isOnline: Ref<boolean>,
  ) {}

  public async execute(
    dataTransferObject: OrderPickingCompletionPluginDto,
    completePipelineWith: CompletePipelineWith<OrderPickingCompletionPluginDto>,
  ): Promise<OrderPickingCompletionPluginDto> {
    try {
      const parsedOrder = parseOrderBeforeSave(
        Order.from(dataTransferObject.order),
      );
      this.loggerService.debug(
        `Order ${dataTransferObject.order.id} prepared for saving`,
        {
          order: dataTransferObject.order,
        },
      );

      await this.ordersService.saveOrder(parsedOrder, true, false, false);
      this.loggerService.info(
        `Picking for Order ${dataTransferObject.order.id} completed ${
          this.isOnline.value ? '' : '(offline)'
        }`,
        {
          orderId: dataTransferObject.order.id,
          orderReference: dataTransferObject.order.orderReference,
        },
      );
    } catch (error) {
      this.loggerService.error(
        `Order ${dataTransferObject.order.id} couldn't be saved`,
        error,
      );
      this.errorHandler.handle(error);

      return completePipelineWith(dataTransferObject);
    } finally {
      await this.router.push({
        name: 'default',
      });
    }

    return dataTransferObject;
  }
}
