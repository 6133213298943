import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/complete-picking-order@2x.png'


const _hoisted_1 = {
  class: "fixed-footer-content page",
  "data-e2e": "picking-footer-border"
}
const _hoisted_2 = { class: "note" }
const _hoisted_3 = { class: "note__header" }
const _hoisted_4 = {
  class: "note__title",
  "data-e2e": "note-title"
}
const _hoisted_5 = { class: "note__list" }
const _hoisted_6 = { class: "item" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = {
  class: "description",
  "data-e2e": "pickup-code-label"
}
const _hoisted_9 = { class: "item" }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = {
  class: "description",
  "data-e2e": "pickup-total-label"
}
const _hoisted_12 = { class: "item" }
const _hoisted_13 = { class: "title" }
const _hoisted_14 = {
  class: "description",
  "data-e2e": "order-pickup-time-label"
}
const _hoisted_15 = {
  class: "fixed-footer-content__footer",
  "data-e2e": "picking-footer"
}
const _hoisted_16 = { class: "fixed-footer-content__footer-button" }

import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { $t } from '@/i18n';
import BaseButton from '@/features/ui/components/Button.vue';
import { formatDateTime } from '@/composables/useDateFormat';
import { AppHeader, BackButton } from '@/features/ui';
import ScreenLoading from '@/features/ui/components/ScreenLoading.vue'; // NOTE:  Failed to resolve component, when importing via barrel
import {
  useOrderPickingCompletion,
  useOrder,
} from '@/features/review/composables';
import { useOrder as usePickingOrder } from '@/features/picking';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';

const backButtonURL = 'picking-labels';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerNote',
  props: {
  id: { type: String, required: true },
},
  setup(__props) {

const props = __props;

const { order, loadOrder, loading } = useOrder();
const { redirectIfOrderNotPicking } = usePickingOrder();
const { startTracking, stopTracking } = usePerformanceTracker();

onMounted(async () => {
  await loadOrder(props.id);
  stopTracking(`printing-to-complete-picking-order-${props.id}`);
  await redirectIfOrderNotPicking(order.value);
});

const formattedTime = computed(() => {
  return order.value && order.value.startTime
    ? formatDateTime(new Date(order.value.startTime))
    : '';
});
const router = useRouter();
const processing = ref(false);

const completeButtonText = computed(() => {
  return hasDeliveryUnit.value
    ? $t('pages.review-customer-note.finish-placing-label-button.text')
    : $t('pages.review-customer-note.finish-picking-button.text');
});

const titleText = computed(() =>
  hasDeliveryUnit.value
    ? $t('pages.review-customer-note.title.text')
    : $t('pages.review-customer-note.title-no-labels.text'),
);

const pickingComplete = async () => {
  if (order && order.value) {
    processing.value = true;

    if (hasDeliveryUnit.value) {
      processing.value = false;
      startTracking(
        `customer-note-to-bags-position-${props.id}`,
        PerformanceThresholdEnum.ROUTE_CHANGE,
      );
      await router.push('bag-position');
      return;
    }

    await useOrderPickingCompletion().completePicking(order);
    processing.value = false;
  }
};

const hasDeliveryUnit = computed<boolean>(
  () => !!order.value?.deliveryUnits.length,
);

const countOfLabels = computed(() => order.value?.numberOfLabels ?? 0);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(order))
      ? (_openBlock(), _createBlock(_unref(AppHeader), {
          key: 0,
          class: "header"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(BackButton), {
              to: { name: backButtonURL, params: { id: __props.id } }
            }, null, 8, ["to"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(loading) || processing.value)
      ? (_openBlock(), _createBlock(ScreenLoading, {
          key: 1,
          title: _unref($t)('pages.review-customer-note.order-saved.title'),
          blur: ""
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "bag" }, [
        _createElementVNode("img", {
          alt: "Customer Note",
          class: "ut-img-contain",
          "data-e2e": "checkout-image",
          height: "200",
          src: _imports_0,
          width: "200"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", _hoisted_4, _toDisplayString(titleText.value), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_unref($t)('pages.review-customer-note.pickup-code.text')), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(order)?.pickupCode), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(_unref($t)('pages.review-customer-note.total.text')), 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString(countOfLabels.value), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, _toDisplayString(_unref($t)('pages.review-customer-note.time.text')), 1),
            _createElementVNode("div", _hoisted_14, _toDisplayString(formattedTime.value), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createVNode(BaseButton, {
            disabled: processing.value,
            btnOutline: "",
            "data-e2e": "checkout-button",
            onClick: pickingComplete
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(completeButtonText.value), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ])
    ])
  ], 64))
}
}

})